import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./global";
import { theme } from "./theme";
import Home from "./routes/home/home.component";


const App = () => {
  return (
    <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <Home />
        </>
      </ThemeProvider>
  );
}

export default App;