import ContactForm from "../../components/contact-form/contact-form.component";
import Logo from "../../assets/DGElogo.png";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { HomeContainer, StyledGroup, StyledFormWrapper } from "./home.styles";

const Home = () => {
    return (
        <>
            <HomeContainer>
                
                <StyledGroup>
                    <img src={Logo} alt="Disc Golf Expo Logo" />
                    <h2>The Southeast Disc Golf Expo</h2>
                    <h3>Florida | Jan 2025</h3>
                    <div>
                        <Link to="https://www.facebook.com/profile.php?id=100090109467704" target='_blank'>
                            <FaFacebook className='socialMediaBtn'/>
                        </Link>
                        <Link to="https://www.instagram.com/the_disc_golf_expo/" target="_blank">
                            <FaInstagram className='socialMediaBtn'/>
                        </Link>    
                    </div>
                </StyledGroup>
                
                <StyledFormWrapper>
                    <ContactForm />
                </StyledFormWrapper>
            </HomeContainer>
        </>
    );
};
    
export default Home;