import styled from "styled-components";


export const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryLight};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    text-rendering: optimizeLegibility;
    margin: 0;
    padding: 2rem 0;

    img {
        max-width: 420px;
    }

    .socialMediaBtn {
        margin-top: 1.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        width: 75px;
        height: 40px;
        color: ${({ theme }) => theme.primaryLight};
        background-color: transparent;
        border: none;
        border-radius: 15px;
        cursor: pointer;

        &:hover {
        background-color: ${({ theme }) => theme.primaryLight};
        color: ${({ theme }) => theme.primaryDark};
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        
        }

    }
    
`;

export const StyledGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const StyledFormWrapper = styled.div`
    display: flex;
    flex-direction: column;

    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
`;