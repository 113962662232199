import styled from "styled-components";

const shrinkLabel = `
    top: -16px; 
    font-size: 12px; 
    color: ${({ theme }) => theme.primaryLight}; 
`;


export const StyledGroup = styled.div`
    position: relative;
    margin-top: 0;
    margin-bottom: 35px 0;
    .form-select {
        background: none;
        background-color: white;
        color: ${({ theme }) => theme.primaryDark};
        font-size: 15px;
        padding: 10px 10px 10px 5px;
        display: block;
        width: 100%;
        border: none;
        border-radius: 8px;
        border-bottom: 1px solid ${({ theme }) => theme.primaryDark};
        margin-top: 0px;
        margin-bottom: 25px;
        &:focus {
            outline: none;
        }
        &:focus ~ .form-select-label {
            ${shrinkLabel}
            color: ${({ theme }) => theme.primaryLight};
        }
    }
    input[type='password'] {
        letter-spacing: 0.3em;
    }
    .form-select-label {
        color: ${({ theme }) => theme.primaryDark};
        font-size: 16px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        transition: 300ms ease all;
        &.shrink {
            ${shrinkLabel}
            color: ${({ theme }) => theme.primaryLight};
        }
    }
`;