import React, { useState } from 'react';
import FormInput from '../form-input/form-input.component';
import FormTextArea from '../form-text-area/form-text-area.component';
import { StyledForm } from './contact-form.styles';
import axios from 'axios';
import FormSelect from '../form-select/form-select.component';

const defaultFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
  role: '',
  company: '',
  marketingConsent: false,
};

const ContactForm = () => {
  const [formValues, setFormValues] = useState(defaultFormValues);
  const { firstName, lastName, email, message, role, company } = formValues;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({ ...formValues, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!lastName || !email || !formValues.marketingConsent) return;

    setIsSubmitting(true);
    setSubmitError(null);
    try {
      const hubspot_response = await submit_hubspot_form(email, firstName, lastName, message, role, company);
      console.log(hubspot_response);
      setFormValues(defaultFormValues);
      setFormSubmitted(true);
    } catch (error) {
      setSubmitError('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
      const submit_hubspot_form = async (email, firstName, lastName, message, role, company) => {
        const portalId = '24380065';
        const formGuid = '7c5dec78-e8b2-4b30-a513-cd953c3cd2ea';
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }
        const hubspotutkCookie = document.cookie.split('; ').find(row => row.startsWith('hubspotutk='));
        const hubspotutk = hubspotutkCookie ? hubspotutkCookie.split('=')[1] : null;

        const response = await axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
          {
            portalId,
            formGuid,
            fields: [
              {
                name: 'firstname',
                value: firstName,
              },
              {
                name: 'lastname',
                value: lastName,
              },
              {
                name: 'email',
                value: email,
              },
              {
                name: 'role',
                value: role,
              },
              {
                name: 'company',
                value: company,
              },
              {
                name: 'message',
                value: message,
              },
            ],
            context : {
              pageUri: 'https://www.discexpo.com',
              pageName: 'Disc Expo Contact Form',
              hutk: hubspotutk,
            }
          },
          config
        );
        return response;
        }



        return (
          <div>
            <StyledForm onSubmit={handleSubmit}>
              <div>
                <FormInput type="text" name="firstName" label="First Name" onChange={handleChange} value={firstName} />
                <FormInput type="text" name="lastName" label="Last Name" onChange={handleChange} value={lastName} />
                <FormInput type="text" name="email" label="Email" onChange={handleChange} value={email} />
                <FormSelect name="role" label="Role" onChange={handleChange} value={role} />
                <FormInput type="text" name="company" label="Company" onChange={handleChange} value={company} />
                <FormTextArea type="text" name="message" label="Question / Comment / Message" onChange={handleChange} value={message} />
              </div>
              <label>
                <input
                  type="checkbox"
                  name="marketingConsent"
                  checked={formValues.marketingConsent}
                  onChange={handleChange}
                  required
                />
                I agree to receive marketing emails, promotional offers, and event updates from discexpo.com
            </label>
              <button type="submit" disabled={isSubmitting}>Send</button>
            </StyledForm>
            {isSubmitting && <p>Sending...</p>}
            {formSubmitted && <p>Thank you! Your form has been submitted. We'll get back to you soon!</p>}
            {submitError && <p>{submitError}</p>}
          </div>
        );
      };
      

      

export default ContactForm;