import { StyledGroup } from "./form-select.styles";

const FormSelect = ({ label, ...otherProps }) => {
    return (
        <StyledGroup>
            <select className='form-select'{...otherProps}>
                <option value="" disabled></option>
                <option value="Attendee">Attendee</option>
                <option value="Sponsor">Sponsor</option>
                <option value="Exhibitor">Exhibitor</option>
                <option value="Vendor">Vendor</option>
                <option value="Speaker">Speaker</option>
            </select>
            {label && ( 
                <label 
                    className={`${ 
                        otherProps.value.length ? 'shrink' : '' 
                    } form-select-label`}>
                        {label}
                </label>  
            )}
        </StyledGroup>
    );
}

export default FormSelect;
